<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card v-if="!allInfoLoaded">
            <v-skeleton-loader type="card, list-item-two-line, actions" width="350"></v-skeleton-loader>
        </v-card>
        <div v-else>
            <v-card v-if="validTrialGen" width="350">
                <v-card-title class="justify-center align-center nuvolos-header">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </v-card-title>
                <h2 class="secondary--text text-center text-h6 mt-5">Sign up for Nuvolos trial</h2>
                <v-card-text>
                    <div class="text-center">
                        <seamless-access-button :redirect="$route.path + '/satosa'" class="mb-4" />
                        <google-oauth-btn :redirect="$route.path + '/google'">Sign up with Google</google-oauth-btn>
                        <div class="d-flex align-center mt-5 mb-2">
                            <v-divider />
                            <span class="caption px-2">OR</span>
                            <v-divider />
                        </div>
                        <email-signup-form @verifiedEmail="trialSignup" :loading="sendingInvitation" class="mt-4"></email-signup-form>
                        <v-dialog :value="showError" max-width="600">
                            <v-card>
                                <v-card-title>Sign-up error</v-card-title>
                                <v-card-text>
                                    <v-alert type="error" class="mt-2">{{ errorContent }}</v-alert>
                                    <div class="mt-2">
                                        If you continue to encounter issues,
                                        <a @click="askForHelp">reach out to us</a>
                                        .
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </div>
                    <div class="text-caption mt-6">
                        By continuing, you agree to Nuvolos's
                        <a href="https://nuvolos.cloud/legal" target="_blank">Terms of Service</a>
                        and
                        <a href="https://nuvolos.cloud/privacy-policy" target="_blank">Privacy Policy</a>
                        , and to receiving emails with updates.
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-else width="500">
                <v-card-title class="justify-center align-center">
                    <v-avatar color="white">
                        <v-icon x-large color="error">error</v-icon>
                    </v-avatar>
                </v-card-title>
                <v-card-text class="font-weight-bold text-center">
                    {{ errorContent }}
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
const EmailSignupForm = () => import('@/modules/invitations/components/EmailSignupForm.vue')
const SeamlessAccessButton = () => import('@/components/SeamlessAccessButton.vue')
const GoogleOauthBtn = () => import('@/components/GoogleOauthBtn.vue')

export default {
    name: 'SignupIndex',
    components: { EmailSignupForm, SeamlessAccessButton, GoogleOauthBtn },
    data() {
        return {
            orgName: '',
            spaceName: '',
            showError: false,
            errorContent: 'Invalid link encountered, please check if you have the right invitation link.',
            loadingTrial: true,
            validTrialGen: true,
            generatingInvitation: true,
            sendingInvitation: false,
            trialSid: null,
            trialDescription: ''
        }
    },
    created() {
        this.getTrialData()
    },
    methods: {
        async getTrialData() {
            try {
                const { data } = await this.$axios.get(`/trial/describe/${this.$route.params.token}`)
                this.trialDescription = data.trial_description
                this.trialSid = data.from_sid
            } catch (error) {
                this.validTrialGen = false
            } finally {
                this.loadingTrial = false
            }
        },
        async trialSignup(info) {
            try {
                this.sendingInvitation = true
                const { data } = await this.$axios.post(`/trial/start`, {
                    email: info.email,
                    g_recaptcha_response: info.recaptchaToken,
                    from_sid: this.trialSid,
                    long_id: info.email,
                    send_invitation: true
                })
                this.sendingInvitation = false
                if (data.url && data.url !== 'null') {
                    this.$nextTick(() => {
                        const pathname = new URL(data.url).pathname
                        const type = this.$route.params.type ? `/${this.$route.params.type}` : ''
                        const url = `${window.location.origin}${pathname}${type}`
                        window.location.href = url
                    })
                } else {
                    this.$router.push({
                        name: 'signup-success'
                    })
                }
            } catch (error) {
                if (error?.response?.data?.code === 'user_already_exists') {
                    this.errorContent = 'User already exists.'
                }
                if (error?.response?.data?.code === 'invalid_from_sid') {
                    this.errorContent = error?.response?.data?.reason
                }
                if (error?.response?.data?.code === 'email_validation_failed') {
                    this.errorContent = 'We could not verify your email as an academic e-mail address, please use the e-mail address of your institution.'
                }
                this.showError = true
            } finally {
                this.sendingInvitation = false
            }
        },
        askForHelp() {
            window.Intercom('showNewMessage', 'Hi Nuvolos Team, Please reach out to me using the above email regarding the following issue:')
        }
    },
    computed: {
        allInfoLoaded() {
            return !this.loadingTrial && !this.$auth.loading
        }
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
